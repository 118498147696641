import React, {useEffect, useState} from "react";
import KRGlue from '@lyracom/embedded-form-glue';
import { useNavigate } from "react-router-dom";
import {QuotationInfo} from "../model/quotation";
import CheckoutClient from "../Clients/CheckoutClient";
import {useTranslation} from "react-i18next";
import "../styles/LaBanquePostale.css";

interface IProps {
    quotation: QuotationInfo
    setQuotation: (quotation: QuotationInfo) => void
}

const LaBanquePostale = (props: IProps) => {
    const checkoutClient = new CheckoutClient();
    const hist = useNavigate();
    const [paymentFailed, setPaymentFailed] = useState<boolean>(false)
    const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>()
    const {i18n} = useTranslation()

    useEffect(() => {
        const endpoint = 'https://static.scelliuspaiement.labanquepostale.fr'

        // Generate the form token
        checkoutClient.PreparePayment(props.quotation.id, "labanquepostale-card")
            .then(res => {
                    const formToken = res.formToken
                    const publicKey = res.jsClientPublicKey
                    return KRGlue.loadLibrary(
                        endpoint,
                        publicKey,
                        formToken,
                    )
                }
            )
            .then(({KR}) =>
                KR.setFormConfig({
                    /* set the minimal configuration */
                    "kr-hide-debug-toolbar" : true,
                    'kr-language': i18n.language === "EN" ? 'en-US' : "fr-FR"
                })
            )
            .then(({KR}) =>
                KR.onSubmit(() => {
                    checkoutClient.ConfirmPayment(props.quotation.id, "labanquepostale-card")
                        .then(response => {
                            if (response.data === true) {
                                props.setQuotation({...props.quotation, status: "Paid"})
                                hist("/thank-you")
                            }
                        })
                    return false
                })
            )
            .then(({KR}) =>
                KR.addForm('#myPaymentForm')
            ) /* add a payment form  to myPaymentForm div*/
            .then(({KR, result}) =>
                KR.showForm(result.formId)
            )
            .catch(error => {
                    setPaymentFailed(true)
                    setPaymentErrorMessage(error)
                }
            )
    }, [props.quotation.id, i18n.language])

    return (
        <div className="form">
                <div id="myPaymentForm"></div>
            {paymentFailed &&
                <>
                    <br/>
                    <br/>
                    <div className={"alert alert-danger"}> {"Payment Failed: " + paymentErrorMessage}  </div>
                </>
            }
        </div>
    )
}

export default LaBanquePostale;
