import React, {useState} from "react";
import {Row, Container} from "react-bootstrap";
import EmailInput from "./EmailInput";
import RequestInvoice from "./RequestInvoice";
import TermsAndConditions from "./TermsAndConditions";

interface Props {
    currentEmail?: string;
    tnc: string;
    quotationId: string;
    showTermsAndConditions: boolean
}

interface Handlers {
    setStatus: (e: boolean) => void;
}

const BankTransferForm = (props: Props & Handlers) => {
    const [email, setEmail] = useState(props.currentEmail);
    const [tncChecked, setTncChecked] = useState(!props.showTermsAndConditions);

    return (
        <Container>
            <Row>
                {props.showTermsAndConditions &&
                    <>
                        <EmailInput setEmail={setEmail} email={email ? email : ""}/>
                        <TermsAndConditions
                            termsAndConditions={props.tnc}
                            tncChecked={tncChecked}
                            setTncChecked={setTncChecked}
                        />
                    </>}
                <RequestInvoice
                    quotationId={props.quotationId}
                    setStatus={props.setStatus}
                    active={tncChecked}
                />
            </Row>
        </Container>
    );
};

export default BankTransferForm;
