import React from "react";
import parser from "html-react-parser"
import {Container, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

interface Props {
  tncChecked: boolean
  termsAndConditions: string
}

interface Handlers {
  setTncChecked: (e: boolean) => void
}

const TermsAndConditions = (props: Props & Handlers) => {


  const {t} = useTranslation();
  
  return (
    
    <Container className="px-0 my-3 rounded"> 
      <h6>{t("tnc")}</h6>
        <textarea className="form-control" style={{resize: "none", height: "10em"}} value={parser(props.termsAndConditions).toString()} />
      <Form.Check checked={props.tncChecked} 
        onChange={() => props.setTncChecked(!props.tncChecked)} 
        className="mt-3" id="terms-conditions-box" inline 
        label={t("acceptTnc")} type="checkbox" />
    </Container>
  )
}

export default TermsAndConditions;
