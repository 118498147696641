import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector"

import english from "./translations/english.json";
import french from "./translations/french.json";

i18n.use(LanguageDetector).init({
  resources: {
    EN: english,
    FR: french
  },
  fallbackLng: "EN",
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation:{
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }


})

export default i18n;
