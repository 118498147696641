
import React, {CSSProperties} from 'react';

interface IProps {
    link: string

    style?: CSSProperties;
}

const PdfViewer = ({ link,style }: IProps) => {
    return (
        <iframe src={link} height={"85%"} width={"100%"} style={style}>
        </iframe>
    );
};

export default PdfViewer;
