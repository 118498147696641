import { useLocation } from "react-router-dom";

export const filterBySupportedMethods = (
  supportedMethods: string[],
  routes: { [key: string]: any },
  section?: string
) => {
  if (!supportedMethods) return [];

  let currentRoutes: string[] = Object.keys(routes);
  if(section) {
    currentRoutes = [...currentRoutes].filter((val) => val==section)
  }
  currentRoutes= [...currentRoutes].filter((v) => supportedMethods.includes(v));
  return currentRoutes.map((k) => routes[k]);
};

export const useGetMethod = () => {
  const pathName = useLocation().pathname;
  const split = pathName.split("/");
  if (split.length > 1) return split[1];
  return "";
};
