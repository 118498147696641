import { BaseUrl } from "../env";
import  { AxiosInstance} from "axios";
import ApiClient from "./ApiClient";

class CheckoutClient {
     private readonly BaseUrl: string;
     private readonly axios :AxiosInstance

    constructor() {
        this.BaseUrl = `${BaseUrl}/checkout/api`;

        this.axios = new ApiClient(`${BaseUrl}/checkout/api`).getInstance();

    }

    async GetQuotation(quotationId: string) {
        return await this.axios.get(`${this.BaseUrl}/quotations/${quotationId}`);
    }

    async UpdateAddress(quotationId: string, body: any) {
        return await this.axios.post(`${this.BaseUrl}/quotations/${quotationId}/address`, body);
    }
    async RequestInvoice(quotationId: string, body: any) {
        return await this.axios.post(`${this.BaseUrl}/payments/${quotationId}/invoice`, body);
    }

    async GetPaymentGateway(QuotationId: string): Promise<string> {
        const rs = await this.axios.get(`${this.BaseUrl}/quotations/${QuotationId}/getpaymentgatewaycode`);
        return rs.data;
    }

    async PreparePayment(quotationId: string, paymentGatewayCode: string) {
        const rs = await this.axios.post(`${this.BaseUrl}/payments/${quotationId}`, {
            paymentGatewayCode: paymentGatewayCode
        });
        return rs.data;
    }

    async ConfirmPayment(quotationId: string, paymentGatewayCode: string) {
        const rs = await this.axios.post(`${this.BaseUrl}/payments/${quotationId}/confirm`, {
            paymentGatewayCode: paymentGatewayCode
        });
        return rs;
    }
    async CreatePayment(quotationId: string) {
        const rs = await this.axios.post(
            `${this.BaseUrl}/payments/${quotationId}`,
            JSON.stringify({paymentGatewayCode: "stripe-card"}));
        return rs;
    }

}

export default CheckoutClient;
