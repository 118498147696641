import React, {JSX, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";

interface Props {
  defaultLanguage: string
}

interface Handlers {}

const LanguageSelect = (props: Props & Handlers) => {
  const {i18n} = useTranslation();
  let urlSearchParams = new URLSearchParams(window.location.search);
  const [selectedLang, setLang] = useState(i18n.language.slice(0, 2).toUpperCase());
  const [displayLangs, setDisplayLangs] = useState(false);
  const [cookies, setCookie] = useCookies(['lang'])

  useEffect(() => {
    changeLang(urlSearchParams.get('lang') ?? cookies?.lang ?? props.defaultLanguage)
  }, [props.defaultLanguage,urlSearchParams?.get('lang')])


  const changeLang = (langKey: string) => {
      setCookie('lang', langKey,{path:'/', expires: new Date(Date.now() + 60 * 60 * 1000) // 1 hour from now
         });
      setLang(langKey);
      i18n.changeLanguage(langKey);
      return;
  }

  const options = Object.entries({
    "EN": "English",
    "FR": "French"
  });


  const displayInvert = (ev: MouseEvent): any => {
    if(displayLangs) setDisplayLangs(false)
  }

  const selectClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setDisplayLangs(!displayLangs)
  }

  const optionClickHandler = (lang: string) => {
    changeLang(lang);
    setDisplayLangs(false);

  }


  const optionsJSX: JSX.Element[] = [];
  for (const [key, value] of options) {
    optionsJSX.push(
      <li className="text-decoration-none text-left ml-0 p-2 w-100 langOption" key={key} onClick={() => optionClickHandler(key)} id={key} style={{cursor:"pointer"}}> {value} </li>
    )
  }



  return (
    <Form  className=" d-inline mr-sm-5 position-relative">
      <div onClick={selectClickHandler} className="p-2 px-3 border text-center text-white bg-traxisblue font-weight-bold " style={{cursor:"pointer"}}>
        {selectedLang} &nbsp; <i className="fas fa-sort-down"></i>
      </div>
        <div className="position-absolute ease-transition-fast text-decoration-none bg-white arrow-box" style={{top: "3em", left: "-3em", width: "8em", display: displayLangs? "block" : "none"}} >
          <ul style={{listStyleType: "none", cursor:"pointer",paddingLeft:'0px'}} className="shadow-lg" >
            {optionsJSX}
          </ul>
        </div>
    </Form>
  )
}

export default LanguageSelect;
