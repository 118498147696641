import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {CreatShipmentResult, QuotationInfo} from "../model/quotation";
import LookupsClient, {ChargesData} from "../Clients/LookupsClient";

interface Props {
  quotation: QuotationInfo;
  expanded: boolean;
  expandable: boolean;
  createShipmentData?:CreatShipmentResult
}

interface Handlers {
  expander: (e: boolean) => void;
}

const Quotation = (props: Props & Handlers) => {
  const { quotation } = props;
  const config = quotation.scenario.configurations.paymentPageConfigurations

  const [charges, setCharges] = useState<ChargesData>({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const ids = quotation.items.map((i) => i.typeId);
    LookupsClient.GetRelevantCharges(i18n.language, ids, setCharges);
  }, [quotation, i18n.language]);

  const items = quotation.items.map((v, i) => {
    return (
      <tr className={`border-0 ${i % 2 === 5 ? "bg-white" : ""} `} key={i}>
        <td>
          <span className="td-label mr-3"> {t("description")} </span>{" "}
          <span>{charges[v.typeId]?.name} </span>
        </td>
        {config.showAmountAndVat && <>
          <td className="text-center text-md-right">
          <span className="text-right td-label mr-3"> {t("amount")} </span>{" "}
          <span>{(Math.round(v.value * 100) / 100).toFixed(2)} </span>
        </td>
          <td className="text-center text-md-right">
          <span className="td-label mr-3"> {t("vat")} </span>{" "}
          <span>{`${v.taxRate * 100}%`} </span>
          </td>
        </>}
        <td className="text-center text-md-right">
          <span className="td-label mr-3"> Total (EUR) </span>{" "}
          {/* <span>{(Math.round(v.totalValue * 100) / 100).toFixed(2)} </span> */}
          <span> {i18n.language.toLowerCase() === "fr" ? (Math.round(v.totalValue * 100) / 100).toFixed(2)?.replace(/[.,]/g, (match) => (match === ',' ? '.' : ',')) : (Math.round(v.totalValue * 100) / 100)?.toFixed(2)}</span>
        </td>
      </tr>
    );
  });

  const shipmentNumber = quotation?.references?.find(
    (v) => v.type === "ShipmentNumber"
  )?.value;

  const orderReference = quotation?.references?.find(
      (v) => v.type === "OrderReference"
  )?.value;

  return (
    <>
      <Container className="bg-light pt-5 border-0 rounded">
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <h3 className={"d-inline-block mx-auto font-weight-bold"}>{quotation.scenario.name ?? quotation.profile.name}</h3>
            <h4>
              {t("quotation")}
              {quotation.status === "Paid" && (
                <h4 className="ml-2  font-weight-bold d-inline-block bg-traxisblue text-white p-1 pr-2 rounded">
                  <i className="ml-1 mr-2 fas fa-check-circle"></i>
                  {t("Paid")}
                </h4>
              )}
            </h4>
            <h5 className="font-weight-bold text-muted">{quotation.id}</h5>
          </Col>
          {(shipmentNumber || orderReference) && (
              shipmentNumber ? (
                  <Col style={{marginTop:"28px"}}>
                    <Row className="my-2 justify-content-end">
                      <Col md={5} sm={12}>
                        <h6 className="d-inline-block font-weight-bold text-traxisblue" >
                          {t("awb")}
                        </h6>
                        <a className="font-weight-bold text-muted cursor-pointer underline" target="_blank" rel="noreferrer" href={`https://gl-net.com/tracking/?gln_tracking=${shipmentNumber}`} > <h6> <u>{shipmentNumber}</u></h6></a>
                      </Col>
                    </Row>
                  </Col>
              ) : (
                  <Col style={{marginTop:"28px"}}>
                    <Row className="my-2 justify-content-end">
                      <Col md={5} sm={12}>
                        <h6 className="d-inline-block font-weight-bold text-traxisblue">
                          {t("orderNumber")}
                        </h6>
                        <h6>{orderReference}</h6>
                      </Col>
                    </Row>
                  </Col>
              )
          )}
        </Row>

        <Row>
          <Col className="px-0">
            <Collapse in={props.expanded}>
              <div>
                <Table
                  hover
                  striped
                  responsive
                  id="charges"
                  className="ease-transition border-0 mb-0"
                >
                  <thead
                    style={{ borderBottom: "3px #000080 solid" }}
                    className="bg-white"
                  >
                    <tr>

                      <th  className="text-left"> {t("description")} </th>
                      {config.showAmountAndVat && <>
                        <th className="text-center text-md-right"> {t("amount")}</th>
                        <th className="text-center text-md-right"> {t("vat")} </th>
                      </>}
                      <th className="text-center text-md-right"> Total (EUR) </th>
                    </tr>
                  </thead>
                  <tbody>{items}</tbody>
                </Table>
              </div>
            </Collapse>
          </Col>
        </Row>

        <Row className="bg-traxisblue rounded-bottom align-bottom">
          <Col style={{display: "flex", justifyContent: "flex-end"}}>
            {props.expandable ? (
              <h5
                onClick={() => props.expander(!props.expanded)}
                className=" clickable text-white float-md-right text-center mt-1 py-1 mr-2"
              >
                <span className="font-weight-bold mr-3 align-bottom">
                  Total:
                </span>
                <span className="font-weight-bold">
                  {/* {quotation.total.toFixed(2)} EUR{" "} */}
                  {i18n.language.toLowerCase() === "fr" ? quotation.total.toFixed(2)?.replace(/[.,]/g, (match) => (match === ',' ? '.' : ',')) : quotation.total.toFixed(2)} EUR{" "}
                </span>
                <i
                    style={{paddingLeft:'2rem',paddingRight:'1.2rem'}}
                  className={`fas  ml-md-5 ml-2  ease-transition fa-chevron-down ${
                    props.expanded ? "collapse-arrow-collapsed" : ""
                  } `}
                ></i>
              </h5>
            ) : (
              <h5 className="text-white float-md-right text-center py-1 mr-4">
                <span className="font-weight-bold mr-3">
                  {" "}
                  Total: {quotation.total.toFixed(2)} EUR{" "}
                </span>
              </h5>
            )}
          </Col>

        </Row>
      </Container>
    </>
  );
};

export default Quotation;
