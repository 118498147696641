import React, {JSX, useContext, useEffect, useState} from "react";
import {Route, useParams, useNavigate, Routes} from "react-router-dom";
import {ProfileInformation} from "../model/profile";
import {QuotationInfo} from "../model/quotation";
import {filterBySupportedMethods} from "../processing/utils";
import BankTransferForm from "./BankTransferForm";
import ChangePaymentMethodButton from "./ChangePaymentMethod";
import CreditCardForm from "./CreditCardForm";
import PaymentMethod from "./PaymentMethod";
import Quotation from "./Quotation";
import ThankYou from "./ThankYou";
import {useTranslation} from "react-i18next";
import {ScenarioInformation} from "../model/scenario";
import CheckoutClient from "../Clients/CheckoutClient";
import LookupsClient from "../Clients/LookupsClient";
import {Col, Row} from "react-bootstrap";
import QuotationShipmentItemDetails from "./QuotationShipmentItemDetails";
import DownloadShipmentAttachment from "./DownloadShipmentAttachment";
import {CreatePublicShipmentContext, ShipmentContextType} from "../Util";

interface Props {
    profile: ProfileInformation;
    scenario: ScenarioInformation;
    returnUrl?: string;
    errUrl?: string;
}

interface Handlers {
    setProfile: (e: ProfileInformation) => void;
    setScenario: (e: ScenarioInformation) => void;
}

const QuotationProfile = (props: Props & Handlers) => {
    const hist = useNavigate();
    const checkoutClient = new CheckoutClient()
    const [quotation, setQuotation] = useState<QuotationInfo | undefined>(undefined);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [detailsExpanded, setDetailsExpanded] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState(false);

    const {
        profile,
        setProfile,
        setScenario,
    } = props;

    let {id,section}: any = useParams();


    const {i18n, t} = useTranslation();
    const [termsAndConditions, setTermsAndConditions] = useState("");
    const context = useContext(CreatePublicShipmentContext)

    const [shipmentCreatedData, _] = context as ShipmentContextType;



    useEffect(() => {
        if (id)
            getQuotation()
    }, []);



    const getQuotation = () => {
        checkoutClient.GetQuotation(id).then((r) => {
            const quote = r.data as QuotationInfo;

            if (quote.status === "Deleted" || quote.status === "Canceled")
                hist("/not-found");
            setQuotation(quote);
            setProfile(quote.profile);
            setScenario(quote.scenario)
        })
            .catch((_) => {
                hist("/not-found");
            });
    }

    useEffect(() => {

        if (!termsAndConditions && quotation?.profile)
            LookupsClient.GetTermsAndConditions(
                i18n.language,
                quotation.profile.name,
                setTermsAndConditions
            )
    }, [i18n.language, quotation, termsAndConditions]);

    let routes: { [key: string]: JSX.Element};

    if (quotation && profile) {
    routes = {
            "credit-card": (
               <>

                        {
                            quotation.paymentMethods?.length > 1 &&
                            <ChangePaymentMethodButton/>
                        }
                        <CreditCardForm
                            encryptedId={id}
                            showTermsAndConditions={quotation.scenario.configurations.paymentPageConfigurations.showTermsAndConditions ?? false}
                            ExtraTextCardUrl={quotation.scenario.configurations.paymentPageConfigurations.extraTextCardUrl}
                            extraTextPayment={quotation.scenario.configurations.paymentPageConfigurations.extraTextPayment}
                            PaymentGatewayLogo={quotation.scenario.configurations.paymentPageConfigurations.paymentGatewayLogo}
                            paymentGateway={quotation.scenario.configurations.paymentPageConfigurations.paymentGateway}
                            tnc={termsAndConditions}
                            quotation={quotation}
                            returnUrl={props.returnUrl}
                            errUrl={props.errUrl}
                            setQuotation={setQuotation}
                            publishableKey={
                                profile.configuration.paymentGatewayConfiguration.publishable_key
                            }
                            setStatus={setPaymentStatus}
                            amount={quotation ? quotation.total : 0}
                            currentEmail={quotation ? quotation.email : ""}
                        />
                    </>
            ),
            "bank-transfer": (
               <>
                        {
                            quotation.paymentMethods?.length > 1 &&
                            <ChangePaymentMethodButton/>
                        }
                        <BankTransferForm
                            quotationId={id!}
                            showTermsAndConditions={quotation.scenario.configurations.paymentPageConfigurations.showAmountAndVat ?? false}
                            tnc={termsAndConditions}
                            setStatus={setPaymentStatus}
                            currentEmail={quotation.email}
                        />
                    </>
            ),
        };
    } else routes = {};
    // @ts-ignore
    return (
        <>
            {quotation ? (
                <>
                    <Quotation
                        expandable={!paymentStatus}
                        expanded={detailsExpanded || paymentStatus}
                        expander={setDetailsExpanded}
                        quotation={quotation}
                    />
                    {(quotation?.shipmentItemDetails?.length > 0 && quotation?.scenario?.configurations?.paymentPageConfigurations?.showShipmentDetails) &&
                        <QuotationShipmentItemDetails
                            quotation={quotation}
                        />
                    }
                    {section=='thank-you' && <>
                            <ThankYou target={paymentMethod}/>
                            {shipmentCreatedData && <DownloadShipmentAttachment shipmentData={shipmentCreatedData}/>}

                        </>}


                            <>
                                { (id && !section &&quotation) && (
                                    <>
                                        {quotation.status !== "Paid" ? (
                                            <PaymentMethod
                                                quotation={quotation}
                                                quotationId={id!}
                                                onRefresh={getQuotation}
                                                supportedMethods={quotation.paymentMethods ?? []}
                                                choosingHandler={(e) => {
                                                    setPaymentMethod(e);
                                                    setDetailsExpanded(false);
                                                }}
                                            />
                                        ) : null}
                                        {quotation?.scenario?.configurations?.paymentPageConfigurations?.showDeliveryProcess &&
                                            <Row className="my-4 justify-content-center">
                                                <Col md={12} sm={12}>
                                                    <h6 className="text-center">{t("orderDeliveryHours")}</h6>
                                                </Col>
                                            </Row>}
                                    </>
                                )}
                            </>



                    {quotation && quotation.status !== "Paid" && section &&  filterBySupportedMethods(profile?.configuration.supportedPaymentMethods, routes,section)}
                </>
            ) : null}
        </>
    );
};

export default QuotationProfile;
