import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

const ChangePaymentMethodButton = () => {

  const {t}  = useTranslation();
  const history = useNavigate();
  const {id}: any = useParams();
  return (
    <Container>
      <Row className="ease-transition clickable" onClick={() => history(`/quote/${id}`)}>
          <Col className="px-0">
            <i style={{fontSize: "1.25em"}} className="fas text-traxisblue fa-arrow-alt-circle-left"></i>
            <span style={{fontSize: "1.25em"}} className="text-muted"> {t("changeMethod")} </span>
          </Col>
        </Row>
    </Container>
  )
}

export default ChangePaymentMethodButton ;
