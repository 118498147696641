import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";

interface ApiResponse<T> {
    data?: T;
    error?: string;
}

class ApiClient {
    private axiosInstance: AxiosInstance;

    constructor(baseURL: string) {
        this.axiosInstance = axios.create({
            baseURL,
            headers: {
                "Content-Type": "application/json",
            },
        });

        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => ({ data: response.data, status: response.status }),
            (error: AxiosError) => {
                const errorMessage = error.response?.data
                    ? Object.values(error.response.data).flatMap(a => a)[0] || "An unknown error occurred"
                    : "An unknown error occurred";
                return Promise.resolve({ error: errorMessage }); // Prevents unhandled errors
            }
        );
    }

    getInstance(): AxiosInstance {
        return this.axiosInstance;
    }
}

export default ApiClient;
