import React from "react";

import {
    Navbar,
    NavbarBrand,
} from "react-bootstrap"
import {ProfileInformation} from "../model/profile";
import LanguageSelect from "./LanguageSelect";
import {ScenarioInformation} from "../model/scenario";

interface Props {
    profile?: ProfileInformation
    scenario?: ScenarioInformation
}

const TraxisHeader = (props: Props) => {
    if (!props.profile) return null;
    return (
        <Navbar className="border-secondary border-bottom bg-white px-4 justify-content-between">
            <NavbarBrand className="mr-auto">
                {
                    props.scenario?.configurations?.iconHref! ?
                        <img alt={props.scenario?.name} src={props.scenario?.configurations?.iconHref} height="50" /> :
                        null
                }
            </NavbarBrand>
            <LanguageSelect
                defaultLanguage={props.scenario?.configurations.paymentPageConfigurations.defaultLanguage?.toUpperCase() ?? "EN"}
            />
        </Navbar>
    )
}

export default TraxisHeader;
