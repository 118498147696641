import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
  target: string
}

interface Handlers {
}

const ThankYou = (props: Props & Handlers) => {

  const {t} = useTranslation();

  const paymentMethods: any = {
    "credit-card": {
      thankYouMessage:
        <div>
          <h5> {t("Your card payment was successful.")} </h5>
          <small className="text-muted"> {t("Your invoice will be sent shortly.")} </small>
        </div>,

    },
    "bank-transfer": {
      thankYouMessage:
        <div>
          <h3 className="text-traxisblue"> {t("Your invoice will be sent shortly.")} </h3>
        </div>

    }
  }

  return paymentMethods[props.target]? 
    <div className="text-center">
      {paymentMethods[props.target].thankYouMessage}
    </div> : null;

}

export default ThankYou;
