import {BaseUrl} from "../env"
import ApiClient from "./ApiClient";

export interface Charge {
  id: string;
  name: string;
}

interface PaymentProfile {
  termsAndConditions: string;
}

const REQUEST_URI = `${BaseUrl}/lookups/api`;

export type ChargesData = { [id: string]: Charge };
const axios = new ApiClient(REQUEST_URI).getInstance();

const getRelevantCharges = (
    lang: string,
    chargeTypeIds: string[],
    callback: (charges: ChargesData) => void
) => {
  axios
      .get(`${REQUEST_URI}/charges`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((v) => {
        const result: Charge[] = v.data.result;
        // Filter to only get the charges requested
        const filtered = result.filter((val) => {
          return chargeTypeIds.includes(val.id);
        });

        // Transform into ChargesData
        const rs: { [id: string]: Charge } = {};
        for (const charge of filtered) {
          rs[charge.id] = charge;
        }
        callback(rs);
      });
};

const getTermsAndConditions = (
    lang: string,
    code: string,
    callback: (termsAndConditions: string) => void
) => {
  axios
      .get(`${REQUEST_URI}/profiles/${code}`, {
        headers: {
          "Accept-Language": lang,
        },
      })
      .then((v) => {
        const result: PaymentProfile = v.data;
        callback(result.termsAndConditions);
      });
};

export default {
  GetRelevantCharges: getRelevantCharges,
  GetTermsAndConditions: getTermsAndConditions,
};
