import React from "react";
import { Table } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QuotationInfo } from "../model/quotation";


interface Props {
    quotation: QuotationInfo;
}

const ShipmentItemDetails = (props: Props) => {
    const { quotation } = props;
    const { t } = useTranslation();

    const shipmentDetails = quotation?.shipmentItemDetails?.map((v, i) => {
        return (
            <tr className={`border-0 ${i % 2 === 5 ? "bg-white" : ""} `} key={i}>
                <td className="text-left">
                    <span className="td-label"> {t("orderDetails")} </span>{" "}
                    <span>{v?.description} ({v?.code}) </span>
                </td>
                <td className="text-center text-md-right">
                    <span className="td-label"> {t("quantity")} </span>{" "}
                    <span>{v?.quantity}</span>
                </td>
            </tr>
        );
    });

    return (
        <Container className="bg-light border-0 ">
            <Row>
                <Col className="px-0">
                    <div>
                        <Table
                            hover
                            striped
                            responsive
                            id="charges"
                            className="ease-transition border-0 mb-0"
                        >
                            <thead
                                style={{ borderBottom: "3px #000080 solid" }}
                                className="bg-white"
                            >
                                <tr>
                                    <th className="text-left"> {t("orderDetails")} </th>
                                    <th className="text-center text-md-right"> {t("quantity")} </th>
                                </tr>
                            </thead>
                            <tbody>{shipmentDetails}</tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ShipmentItemDetails;
