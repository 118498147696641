import React from "react";
import {Container, Image} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import InternalErrorImage from "../internalError.svg"
import BackButton from "./BackButton";
import {useLocation} from "react-router-dom";

interface Props {
}

interface Handlers {

}

const InternalError = (props: Props & Handlers) => {
    const location = useLocation();
    const {t} = useTranslation();
const urlParams = new URLSearchParams(location.search);
const message = urlParams.get('e');
  return (
    <Container>
      <Image className="mx-auto d-block h-75 w-75" fluid src={InternalErrorImage} alt="500 Error" />
      <h4 className="text-traxisblue text-center mb-3">{t("Internal Error")}</h4>
        <h5 className="text-muted text-center mb-3">{message}</h5>
      <BackButton className="d-block mx-auto" />
    </Container>
  )
}

export default InternalError;
