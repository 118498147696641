import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../env";
import CheckoutClient from "../Clients/CheckoutClient";

interface Props {
  active: boolean;
  style?: React.CSSProperties;
  className?: string;
  quotationId: string;
}

interface Handlers {
  setStatus: (e: boolean) => void;
}

const RequestInvoice = (props: Props & Handlers) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
    const hist = useNavigate();
    const checkoutClient = new CheckoutClient()
  const requestInvoice = async () => {
    setIsLoading(true);
    await checkoutClient.RequestInvoice(props.quotationId,JSON.stringify({method: "email"}))
           setIsLoading(false);
           props.setStatus(true);
           hist("/thank-you");
  };

  return (

      <Button
        disabled={!props.active || isLoading}
        style={props.style}
        className={`bg-traxisblue p-3 ${props.className}`}
        onClick={()=> requestInvoice()}
      >
        {isLoading ? (
          <Spinner animation="border" style={{ width: "4em", height: "4em" }} />
        ) : (
          t("requestInvoice")
        )}
      </Button>

  );
};

export default RequestInvoice;
