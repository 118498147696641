import { Button } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  className?: string
}

const BackButton = (props: Props) => {

  const history = useNavigate();


  return (
    <Button onClick={() => history(-1) } className={`bg-white p-3 text-traxisblue  border border-traxisblue ${props.className} border-thick badge-pill `}  >
      <i className="fas fa-arrow-left"></i> GO BACK
    </Button>
  )
}

export default BackButton;
