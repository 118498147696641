import React from "react";
import {Form, Container} from "react-bootstrap"

interface Props {
  email: string
}

interface Handlers {
  setEmail: (e: string) => void
}

const EmailInput = (props: Props & Handlers) => {
  return (
    <Container className="px-0 my-3">
      <h6>Email</h6>
      <Form.Control className="p-4" type="email" onChange={(e) => props.setEmail(e.target.value)} value={props.email} />
    </Container>
  )
}

export default EmailInput;
