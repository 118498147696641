import {Button} from "react-bootstrap";
import {CreatShipmentResult} from "../model/quotation";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import PdfViewer from "./PdfViewer";


type Props = {
    shipmentData?: CreatShipmentResult
}

const DownloadShipmentAttachment = ({shipmentData}: Props) => {
    const {t} = useTranslation();
    const labelLink = shipmentData.shipment?.attachments?.find((e) => e?.type === "Label")?.location;
    const [pdfHeight, setPdfHeight] = useState(window.innerWidth < 768 ? "440px" : "800px");

    const handlerDownload = () => {
        shipmentData?.shipment.attachments.forEach((attachment) => {
            const a = document.createElement('a');
            a.href = attachment.location;
            a.download = attachment.fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })

    }
    useEffect(() => {
        const handleResize = () => {
            setPdfHeight(window.innerWidth < 768 ? "440px" : "800px");
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <div className={'text-center mt-3'}>
            {t["downloadGeneratedShipment"]}
            {(Boolean(shipmentData?.shipment) && shipmentData?.shipmentGenerationRequired) &&
                <div className={'d-flex align-items-center flex-column'} style={{rowGap:'1rem'}}>

                <Button className="bg-traxisblue text-white"
                        onClick={handlerDownload}>
                    {t("downloadGeneratedShipment")}
                </Button>
                    {labelLink &&<PdfViewer link={`${labelLink}?view=true`} style={{ height:pdfHeight,width:"70%",minWidth:"350px"}}/>}
                </div>
                }
            {!shipmentData?.shipment && shipmentData?.shipmentGenerationRequired && <p>
                {t('contactIssuePublicShipment')}
            </p>}

        </div>
    )
}
export default DownloadShipmentAttachment
