import "./App.css";
import React, {useEffect, useMemo, useState} from "react";
import {Container} from "react-bootstrap";
import TraxisHeader from "./components/TraxisHeader";
import {Routes, Route, useLocation} from "react-router-dom";
import NotFound from "./components/NotFound";
import QuotationPage from "./components/QuotationPage";
import InternalError from "./components/InternalError";
import {ProfileInformation} from "./model/profile";
import {ScenarioInformation} from "./model/scenario";
import CreatePublicShipmentContextProvider from "./components/CreatePublicShipmentContextProvider";

const Main = () => {

    const [profile, setProfile] = useState<ProfileInformation | undefined>(undefined);
    const [scenario, setScenario] = useState<ScenarioInformation | undefined>(undefined);
    const [returnUrl, setReturnUrl] = useState<string | undefined>(undefined);
    const [errUrl, setErrUrl] = useState<string | undefined>(undefined);
    const location = useLocation();

    useEffect(() => {
        const returnUrl = new URLSearchParams(location.search).get("returnUrl");
        const errUrl = new URLSearchParams(location.search).get("errUrl");
        if (returnUrl) setReturnUrl(returnUrl);
        if (errUrl) setErrUrl(errUrl);
    }, [location.search]);

    const renderQuotationPage = useMemo(() => (
        <CreatePublicShipmentContextProvider>
            <QuotationPage
                profile={profile!}
                scenario={scenario!}
                returnUrl={returnUrl}
                errUrl={errUrl}
                setProfile={setProfile}
                setScenario={setScenario}
            />
        </CreatePublicShipmentContextProvider>
    ), [profile, scenario, returnUrl, errUrl]);

    return (
        <div className="mx-auto">
            <TraxisHeader profile={profile} scenario={scenario} />
            <Container className="p-4">
                <Routes>
                    <Route path="/error" element={<InternalError />} />
                    <Route path="/" element={<NotFound />} />
                    <Route path="/:section" element={renderQuotationPage} />
                    <Route path="/quote/:id" element={renderQuotationPage} />
                    <Route path="/quote/:id/:section" element={renderQuotationPage} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Container>
        </div>
    );
};

export default Main;
