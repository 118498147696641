import React from "react";

interface Props {

}

interface Handlers {

}

const CreditCard = (props: Props & Handlers) => {
    return (
        <svg className="methodIcon mb-3" viewBox="0 0 122 80" xmlns="http://www.w3.org/2000/svg">
            <g >
                <path d="M122 13.1457C122 31.2272 122 49.3087 122 67.3902C118.177 76.9353 113.585 79.9941 103.076 79.9941C74.7453 79.9941 46.4142 79.9995 18.0831 79.9778C16.2884 79.9778 14.4502 79.9616 12.7097 79.5819C6.02951 78.123 1.94116 73.8548 0 67.3902C0 49.1297 0 30.8693 0 12.6034C3.62747 3.13963 8.26347 -0.000496037 18.6253 -0.000496037C47.1245 -0.000496037 75.6292 -0.0059194 104.128 0.015774C105.918 0.015774 107.767 0.0266208 109.491 0.438796C116.263 2.06038 120.362 6.42076 122 13.1457ZM112.717 32.2848C78.1396 32.2848 43.8604 32.2848 9.62987 32.2848C9.48889 32.5831 9.35333 32.7349 9.34791 32.8922C9.3208 43.5599 9.27742 54.2223 9.30996 64.89C9.3208 67.4336 10.8607 69.1962 13.1326 69.9717C14.7864 70.5412 16.6571 70.6768 18.4356 70.6768C46.36 70.7201 74.279 70.7039 102.203 70.7039C103.559 70.7039 104.915 70.7256 106.27 70.6388C110.321 70.373 112.701 67.8512 112.712 63.8054C112.733 56.6628 112.717 49.5202 112.717 42.3831C112.717 39.0586 112.717 35.7395 112.717 32.2848ZM112.717 22.7451C112.717 20.2666 112.739 18.1081 112.712 15.9496C112.663 12.1966 110.348 9.70732 106.579 9.37107C105.592 9.2843 104.595 9.29515 103.602 9.29515C75.1466 9.28972 46.6962 9.28972 18.2404 9.29515C17.1559 9.29515 16.0552 9.23007 14.9979 9.41446C12.3301 9.87002 9.96062 11.1879 9.52142 13.9484C9.06596 16.8119 9.4184 19.8002 9.4184 22.7451C43.9417 22.7451 78.1505 22.7451 112.717 22.7451Z"  />
                <path d="M34.7944 58.5885C29.1011 58.5885 23.6301 58.5885 18.0235 58.5885C18.0235 55.6057 18.0235 52.753 18.0235 49.6671C23.5216 49.6671 29.0523 49.6671 34.7944 49.6671C34.7944 52.5361 34.7944 55.4593 34.7944 58.5885Z"  />
                <path d="M38.0423 58.5723C38.0423 55.4593 38.0423 52.5957 38.0423 49.5912C43.6272 49.5912 49.0874 49.5912 54.7211 49.5912C54.7211 52.5903 54.7211 55.5081 54.7211 58.5723C49.1687 58.5723 43.7085 58.5723 38.0423 58.5723Z"  />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="122" height="80" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default CreditCard;
