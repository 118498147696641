import React from "react";
import NotFoundImage from "../notFound.svg"
import {Container, Image} from "react-bootstrap"
import {useTranslation} from "react-i18next";


const NotFound = () => {

  const {t} = useTranslation();

  return (
    <Container>
      <Image className="mx-auto d-block h-75 w-75" fluid src={NotFoundImage} alt="404 Not Found" />
      <h4 className="text-traxisblue text-center mb-3">{t("The page you are looking for does not exist!")}</h4>
    </Container>
  )
}

export default NotFound;
