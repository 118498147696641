import React, {JSX} from "react";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import BankTransfer from "./BankTransfer";
import CreditCard from "./CreditCard";

interface Props {
    methodId: string
    title: string
    location: string
}


interface Handlers {
    onClick: (choice: string) => void
}

const PaymentMethodChoice = (props: Props & Handlers) => {
    const icons: any  = {
      "credit-card": <CreditCard />,
      "bank-transfer": <BankTransfer />
    }
    const location = useLocation();

    const {t} = useTranslation();

    return (
      <Link onClick={() => props.onClick(props.methodId)}  to={`${location.pathname}${props.location}`}>
        <div className="border my-3 rounded shadow-lg payment-method-choice p-3 text-center">
            <h3 className="font-weight-bold ">
              {t(props.title)}
            </h3>
            <div className="mx-auto w-50">
              {icons[props.methodId] as JSX.Element}
            </div>
        </div>
      </Link>
    )
}

export default PaymentMethodChoice;
