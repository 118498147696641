import React, {JSX, useState} from "react";
import {CreatShipmentResult} from "../model/quotation";
import {CreatePublicShipmentContext} from "../Util";

const CreatePublicShipmentContextProvider = ({ children}:{children:JSX.Element}) => {
    const [shipmentCreatedData, setShipmentCreatedData] = useState<CreatShipmentResult | undefined>(undefined);

    return (
        <CreatePublicShipmentContext.Provider value={[shipmentCreatedData, setShipmentCreatedData]}>
            {children}
        </CreatePublicShipmentContext.Provider>
    );
};

export default CreatePublicShipmentContextProvider
