import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import React, {useContext, useEffect, useState} from "react";

import {
    Container,
    Modal,
    Row,
    Form,
    Col,
    Button,
    Spinner,
} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {CreatShipmentResult, QuotationInfo} from "../model/quotation";

import {useTranslation} from "react-i18next";
import {CreatePublicShipmentContext, ShipmentContextType} from "../Util";
import CheckoutClient from "../Clients/CheckoutClient";

interface Props {
    encryptedId: string
    amount: number;
    tncChecked: boolean;
    quotation: QuotationInfo;
    returnUrl?: string;
    errUrl?: string;
}

interface Handlers {
    setStatus: (e: boolean) => void;
    setQuotation: (e: QuotationInfo) => void;
}

const StripeForm = (props: Props & Handlers) => {
    const stripe = useStripe()!;
    const elements = useElements()!;
    const checkoutClient = new CheckoutClient();
    const context=useContext(CreatePublicShipmentContext)
       const [_, setShipmentCreatedData] = context as ShipmentContextType;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const hist = useNavigate();
    const [isPaying, setIsPaying] = useState(false);
    const [paymentFailed, setPaymentFailed] = useState(false);
    const [payErrorText, setPayErrorText] = useState("");

    useEffect(() => {
        if (params.payment_intent) {

            check3dPayment();
        }
    }, [stripe]);

    const {t} = useTranslation();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        pay();
    };

    const check3dPayment = async () => {
        if (!stripe) {
            return;
        }


        const result = await stripe.retrievePaymentIntent(params.payment_intent_client_secret);
        if(result.error || result.paymentIntent?.status === 'requires_payment_method'){
            if (props.errUrl) {
                window.location.href = props.errUrl;
                return;
            }
            setIsPaying(false);
            setPayErrorText( "Authentication Failed");
            setPaymentFailed(true);
        }
        else {
            const res=await checkoutClient.ConfirmPayment(props.quotation.id,"stripe-card")

            if(!res.data)
                return;

            props.setQuotation({...props.quotation, status: "Paid"});
            setIsPaying(false);
            if (props.returnUrl) {
                window.location.href = props.returnUrl;
            } else hist("/thank-you");
        }



    }
    const pay = async () => {
        setPaymentFailed(false);
        setPayErrorText( "");
        setShowConfirm(false);
        setIsPaying(true);
        setPayErrorText("");

        const rs= await checkoutClient.CreatePayment(props.quotation.id)

        if(rs.status!==200){
            setIsPaying(false);
            setPayErrorText(rs["error"]);
            setPaymentFailed(true);
            return
        }



        const intent = await stripe.retrievePaymentIntent(rs.data.clientSecret);
        if (intent.paymentIntent?.status === "succeeded") {
            hist(`/error?e=Quotation Reference ${props.quotation.id} --- Payment Already Paid`);
            return;
        }
        const confirmIntent = await stripe.confirmCardPayment(rs.data.clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement)!,
            },
            return_url: `${window.origin}/quote/${props.encryptedId}/credit-card`
        }, {
            handleActions: false,
        });
        if (confirmIntent.error) {
            if (props.errUrl) {
                window.location.href = props.errUrl;
                return;
            }
            setIsPaying(false);
            setPayErrorText(confirmIntent.error.message ?? "");
            setPaymentFailed(true);
            return;
        }

        const action = confirmIntent.paymentIntent?.next_action;
        if (action && action.type === 'redirect_to_url' && action.redirect_to_url?.url) {

            window.location.assign(action.redirect_to_url?.url);
            return;
        }


        const res=await checkoutClient.ConfirmPayment(props.quotation.id,"stripe-card")
        if(!res.data) {
            setIsPaying(false);
            setPayErrorText(res["error"]);
            setPaymentFailed(true);
            return
        }
        setShipmentCreatedData({...res.data as CreatShipmentResult})
        props.setQuotation({...props.quotation, status: "Paid"});
        setIsPaying(false);
        if (props.returnUrl) {
            window.location.href = props.returnUrl;
        } else hist("/thank-you");
        props.setStatus(true);
    };

    const [numberComplete, setNumberComplete] = useState(false);
    const [expiryComplete, setExpiryComplete] = useState(false);
    const [cvvComplete, setCvvComplete] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    return (
        <>
            <div className="ease-transition">
                <Form onSubmit={handleSubmit}>
                    <Container
                        className={`rounded p-5 mb-2 ml-0`}
                    >
                        <Row className="mb-3">
                            <Col>
                                <Form.Label className="font-weight-bold">
                                    {` ${t("cardNumber")} `}
                                </Form.Label>
                                <CardNumberElement
                                    options={{
                                        disabled: !props.tncChecked,
                                        showIcon: true,
                                        placeholder: "**** **** **** ****",
                                    }}
                                    onChange={(e) => setNumberComplete(e.complete)}
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-between">
                            <Col>
                                <Form.Label className="font-weight-bold"> {t("expiry")} </Form.Label>
                                <CardExpiryElement
                                    options={{
                                        disabled: !numberComplete,
                                        placeholder: t("mm/yy")
                                    }}
                                    onChange={(e) => setExpiryComplete(e.complete)}
                                    className="form-control"
                                />
                            </Col>
                            <Col>
                                <Form.Label className="font-weight-bold"> CVC </Form.Label>
                                <CardCvcElement
                                    options={{disabled: !(numberComplete && expiryComplete)}}
                                    onChange={(e) => setCvvComplete(e.complete)}
                                    className="form-control"
                                />
                            </Col>
                        </Row>
                    </Container>

                    <Button
                        disabled={
                            !(
                                props.tncChecked &&
                                numberComplete &&
                                expiryComplete &&
                                cvvComplete
                            )
                        }
                        type="submit"
                        className="bg-traxisblue mt-2 p-3 h-25"
                    >
                        {isPaying ? (
                            <Spinner
                                size="sm"
                                color="white"
                                animation="border"
                                className="m-0 p-0"
                            />
                        ) : (
                            <div>
                                {" "}
                                <span className="font-weight-bold"> {t("pay")} </span>{" "}
                                <span> {props.amount} EUR </span>{" "}
                            </div>
                        )}
                    </Button>
                    {paymentFailed &&
                        <>
                            <br/>
                            <br/>
                            <div className={"alert alert-danger"}> {"Payment Failed: " + payErrorText}  </div>
                        </>

                    }
                </Form>
                <Modal
                    dialogClassName="modal-dialog-centered"
                    fade
                    show={showConfirm}
                    onHide={() => setShowConfirm(false)}
                    className="rounded"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {" "}
                            Are you sure you want to proceed with the payment?
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button onClick={() => setShowConfirm(false)} variant="secondary">
                            Cancel
                        </Button>
                        <Button
                            className="bg-traxisblue text-white"
                            onClick={(_) => pay()}
                        >
                            Pay {props.amount} EUR
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default StripeForm;
