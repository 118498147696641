import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import EmailInput from "./EmailInput";
import TermsAndConditions from "./TermsAndConditions";
import StripeForm from "./StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { QuotationInfo } from "../model/quotation";
import LaBanquePostale from "./LaBanquePostale";
import CheckoutClient from "../Clients/CheckoutClient";
import { useTranslation } from "react-i18next";

interface Props {
    encryptedId: string;
    currentEmail?: string;
    quotation: QuotationInfo;
    amount: number;
    returnUrl?: string;
    errUrl?: string;
    publishableKey: string;
    showTermsAndConditions: boolean;
    ExtraTextCardUrl?: string;
    extraTextPayment?: string;
    PaymentGatewayLogo?: string;
    paymentGateway?: string;
    tnc: string;
}

interface Handlers {
    setStatus: (e: boolean) => void;
    setQuotation: (e: QuotationInfo) => void;
}

const CreditCardForm = (props: Props & Handlers) => {
    const { t } = useTranslation();

    const [email, setEmail] = useState(props.currentEmail);
    const [tncChecked, setTncChecked] = useState(!props.showTermsAndConditions);

    const [stripe, setStripe] = useState<Stripe | null>(null); // Ensure correct type for stripe
    const [paymentGateway, setPaymentGateway] = useState<string | null>(null);

    useEffect(() => {
        // Asynchronously load Stripe and set it in the state
        const loadStripeAsync = async () => {
            const stripeInstance = await loadStripe(props.publishableKey);
            setStripe(stripeInstance);
        };

        loadStripeAsync();
    }, [props.publishableKey]);

    useEffect(() => {
        const checkoutClient = new CheckoutClient();
        checkoutClient.GetPaymentGateway(props.quotation.id).then((v) => setPaymentGateway(v));
    }, [props.quotation]);

    // If stripe is not initialized yet, show a loading state
    if (!stripe) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {props.showTermsAndConditions && (
                <>
                    <EmailInput setEmail={setEmail} email={email || ""} />
                    <TermsAndConditions
                        termsAndConditions={props.tnc}
                        tncChecked={tncChecked}
                        setTncChecked={setTncChecked}
                    />
                </>
            )}
            {props.extraTextPayment && props.paymentGateway && (
                <Row className={"mx-1 my-2"}>
                    <Col>
                        <Row className={"justify-content-center"}>
                            <h6 className="text-center">
                                {t(
                                    props.paymentGateway.toLowerCase() === "lbp"
                                        ? "paymentSecuredAndEncryptedLBP"
                                        : "paymentSecuredAndEncryptedStripe"
                                )}
                                {props.PaymentGatewayLogo && (
                                    <img
                                        width={40}
                                        height={40}
                                        src={props.PaymentGatewayLogo}
                                        alt={"PaymentGatewayLogo"}
                                    />
                                )}
                            </h6>
                        </Row>
                    </Col>
                </Row>
            )}
            <Card>
                {props.ExtraTextCardUrl && (
                    <Card.Header>
                        <Row>
                            <Col>
                                <h6> {t("creditCards")}</h6>
                            </Col>
                            <Col className={"text-right"}>
                                <img
                                    src={props.ExtraTextCardUrl}
                                    alt={"Credit Cards"}
                                />
                            </Col>
                        </Row>
                    </Card.Header>
                )}
                <Card.Body>
                    {/* Pass the elementsProps with correctly typed stripe object */}
                    <Elements stripe={stripe} >
                        {paymentGateway === "stripe-card" && (
                            <StripeForm
                                encryptedId={props.encryptedId}
                                errUrl={props.errUrl}
                                returnUrl={props.returnUrl}
                                quotation={props.quotation}
                                setQuotation={props.setQuotation}
                                setStatus={props.setStatus}
                                tncChecked={tncChecked}
                                amount={props.amount}
                            />
                        )}
                        {paymentGateway === "labanquepostale-card" && (
                            <LaBanquePostale
                                quotation={props.quotation}
                                setQuotation={props.setQuotation}
                            />
                        )}
                    </Elements>
                </Card.Body>
            </Card>
        </>
    );
};

export default CreditCardForm;
