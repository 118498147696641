import React from "react";

interface Props {

}

interface Handlers {

}

const BankTransfer = (props: Props & Handlers) => {
    return (
      <svg className="methodIcon mb-3" viewBox="0 0 127 106" xmlns="http://www.w3.org/2000/svg">
<path d="M63.5265 36.629C71.9493 22.2625 87.8745 16.5032 102.06 20.4485C116.094 24.3541 126.307 36.986 126.963 51.2442C127.648 66.1396 118.895 79.4167 104.971 84.8375C92.1057 89.8459 73.1684 86.3448 63.4868 69.6777C56.9787 80.091 47.7943 86.2284 35.4233 86.8922C25.628 87.4157 17.1814 83.9966 10.1629 77.1796C-3.65998 63.7571 -3.36115 41.5686 10.9298 28.3285C18.903 20.9403 28.4418 18.0025 39.2102 19.7134C49.9152 21.4163 57.817 27.3792 63.5265 36.629ZM122.764 53.1296C122.798 36.9437 109.456 23.5185 93.2958 23.4762C76.9923 23.4339 63.5609 36.7242 63.5159 52.9471C63.471 69.244 76.7781 82.6956 92.9811 82.7353C109.356 82.7723 122.729 69.4794 122.764 53.1296ZM4.26563 54.5469C4.37934 60.7293 6.90485 67.4115 12.0802 73.0465C26.4637 88.7088 51.5125 84.8375 60.7815 65.3198C61.0168 64.8254 61.0671 64.1035 60.9058 63.5825C58.7479 56.6412 58.7293 49.7157 60.8661 42.7665C61.0406 42.198 61.038 41.4152 60.7788 40.8996C55.585 30.4969 47.2601 24.3806 35.6084 23.5635C18.7073 22.3788 4.26034 36.1663 4.26563 54.5469Z" />
<path d="M30.8799 16.8707C29.9755 15.8183 29.1213 14.824 28.2725 13.8324C40.26 2.37726 63.2779 -5.35736 85.9545 5.23841C87.0996 3.5672 88.2606 1.87219 89.5431 -0.00262451C92.1057 5.04802 94.5783 9.92149 97.1567 15.0091C91.4763 14.7236 86.0101 14.4486 80.2556 14.1577C81.398 12.3463 82.44 10.6989 83.4793 9.04886C73.1868 3.19171 49.8305 0.338491 30.8799 16.8707Z" />
<path d="M40.9793 100.896C39.8898 102.483 38.7262 104.178 37.4754 106C34.9128 100.941 32.4349 96.0495 29.8698 90.9882C35.5502 91.3452 41.0217 91.6863 46.7814 92.0486C45.6284 93.8653 44.5838 95.5127 43.5366 97.1601C53.4006 102.893 77.1853 105.884 95.7234 89.7798C96.6066 90.8005 97.4899 91.8238 98.516 93.0111C80.8586 107.169 61.7282 109.305 40.9793 100.896Z" />
<path d="M91.0374 36.1742C91.0374 34.6987 91.0374 33.3924 91.0374 32.0121C92.439 32.0121 93.7427 32.0121 95.1945 32.0121C95.1945 33.3237 95.1945 34.6617 95.1945 36.1769C98.7699 35.9283 101.888 36.6158 104.117 39.5404C105.714 41.6374 106.021 44.0252 105.775 46.6537C104.453 46.6537 103.115 46.6537 101.602 46.6537C101.652 44.6678 101.769 42.592 99.8515 41.2117C98.4843 40.2253 96.9505 40.3919 95.3373 40.4316C95.3373 43.9432 95.3373 47.3967 95.3373 51.0009C98.6113 50.7523 101.576 51.3632 103.821 53.9784C105.685 56.1494 105.926 58.717 105.849 61.4222C105.77 64.1908 104.741 66.449 102.599 68.2075C100.486 69.9421 97.9686 70.1563 95.2897 70.032C95.2897 71.46 95.2897 72.7689 95.2897 74.1704C93.8564 74.1704 92.5209 74.1704 91.0347 74.1704C91.0347 72.8377 91.0347 71.497 91.0347 70.0109C87.4831 70.2859 84.4182 69.5772 82.1994 66.7108C80.5572 64.59 80.2478 62.1705 80.5122 59.5262C81.8794 59.5262 83.2176 59.5262 84.5451 59.5262C85.3728 65.2538 86.1186 65.9889 90.9184 65.7509C90.9184 62.308 90.9184 58.8545 90.9184 55.2212C90.2678 55.2212 89.5802 55.2318 88.8953 55.2186C84.1405 55.1393 80.4831 51.4637 80.454 46.696C80.4461 45.469 80.4038 44.2077 80.6392 43.0151C81.4563 38.9085 84.8386 36.2007 89.0117 36.1742C89.6226 36.1716 90.2334 36.1742 91.0374 36.1742ZM90.9422 50.9322C90.9422 47.4046 90.9422 43.9538 90.9422 40.5109C86.9701 40.0799 84.8704 41.5977 84.6535 44.7339C84.2992 49.848 86.8035 51.4161 90.9422 50.9322ZM95.3135 65.7324C97.7068 65.9704 99.9679 65.9096 101.042 63.5482C102.036 61.3613 102.134 58.9656 100.682 56.8819C99.3782 55.0123 97.3604 55.0494 95.3135 55.3217C95.3135 58.8202 95.3135 62.2366 95.3135 65.7324Z" />
<path d="M16.8508 44.6281C15.4677 44.6281 14.1931 44.6281 12.736 44.6281C12.736 43.3218 12.691 42.0605 12.7703 40.8071C12.7888 40.5294 13.204 40.191 13.5161 40.0349C20.0824 36.7269 26.654 33.4321 33.2415 30.1717C33.5985 29.9945 34.1988 30.0051 34.5611 30.1849C41.1512 33.4453 47.7255 36.7348 54.2786 40.064C54.6382 40.2465 54.9741 40.815 55.0138 41.2328C55.1169 42.3143 55.0481 43.4144 55.0481 44.6017C53.6254 44.6017 52.319 44.6017 50.9148 44.6017C50.9148 49.5624 50.9148 54.4279 50.9148 59.386C51.5362 59.4283 52.1709 59.4733 52.8981 59.5235C52.8981 60.8748 52.8981 62.1837 52.8981 63.6196C53.6201 63.6619 54.2548 63.6989 54.9688 63.7386C54.9688 65.1057 54.9688 66.4463 54.9688 67.8558C40.9291 67.8558 26.929 67.8558 12.8285 67.8558C12.8285 66.531 12.8285 65.1929 12.8285 63.7571C13.442 63.7148 14.0794 63.6698 14.8066 63.6196C14.8066 62.2736 14.8066 60.9647 14.8066 59.5315C15.5286 59.4865 16.1632 59.4469 16.8508 59.4045C16.8508 54.4703 16.8508 49.5756 16.8508 44.6281ZM29.7481 44.6995C29.7481 49.6629 29.7481 54.5205 29.7481 59.3464C32.5857 59.3464 35.328 59.3464 38.0519 59.3464C38.0519 54.4121 38.0519 49.5836 38.0519 44.6995C35.2698 44.6995 32.5566 44.6995 29.7481 44.6995ZM21.2804 44.7048C21.2804 49.6655 21.2804 54.5231 21.2804 59.3517C22.7084 59.3517 24.0439 59.3517 25.3582 59.3517C25.3582 54.42 25.3582 49.5941 25.3582 44.7048C23.9936 44.7048 22.6899 44.7048 21.2804 44.7048ZM42.4206 59.3675C43.8301 59.3675 45.1656 59.3675 46.4905 59.3675C46.4905 54.4227 46.4905 49.5651 46.4905 44.7048C45.0942 44.7048 43.7904 44.7048 42.4206 44.7048C42.4206 49.5941 42.4206 54.42 42.4206 59.3675Z" />
</svg>

    )
}

export default BankTransfer;
