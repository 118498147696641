import React, {JSX, useState} from "react";
import {
    Accordion,
    Button,
    Col, Collapse,
    Container,
    Form,
    FormControl,
    FormLabel,
    Row,
} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {filterBySupportedMethods} from "../processing/utils";
import PaymentMethodChoice from "./PaymentMethodChoice";
import countries from "../countries.json";
import {QuotationInfo} from "../model/quotation";
import CheckoutClient from "../Clients/CheckoutClient";
import {useLocation,useNavigate} from "react-router-dom";

interface Props {
    supportedMethods: string[];
    quotation: QuotationInfo;
    onRefresh: () => any
    quotationId: string
}

interface Handlers {
    choosingHandler: (choice: string) => void;
}

const TextInput: React.FC<{
    label: string;
    setFunc: Function;
    val: string;
    readonly?: boolean
}> = ({label, setFunc, val, readonly}) => {
    return (
        <div>
            <FormLabel>{label} </FormLabel>
            <FormControl
                value={val}
                readOnly={readonly}
                onChange={({target: {value}}) => setFunc(value)}
            />
        </div>
    );
};

const PaymentMethod = (props: Props & Handlers) => {
    const {t} = useTranslation();

    const checkoutClient = new CheckoutClient()

    const methods: { [key: string]: JSX.Element } = {
        "credit-card": (
            <PaymentMethodChoice
                title="Credit Card"
                location="/credit-card"
                methodId="credit-card"
                onClick={props.choosingHandler}
            />
        ),
        "bank-transfer": (
            <PaymentMethodChoice
                title="Bank Transfer"
                location="/bank-transfer"
                methodId="bank-transfer"
                onClick={props.choosingHandler}
            />
        ),
    };

    // name, company name, street, addressline2, zipcode, city, country(DDL) , VAT, EORI

    const {quotation} = props;

    const updateAddressInputConfigurations = quotation.scenario.configurations.paymentPageConfigurations.updateAddressInputConfigurations

    const [name, setName] = useState(quotation.billingAddress?.name ?? "");
    const [companyName, setCompanyName] = useState(quotation.billingAddress?.companyName ?? "");
    const [streetOne, setStreetOne] = useState(quotation.billingAddress?.street ?? "");
    const [streetTwo, setStreetTwo] = useState(quotation.billingAddress?.addressLine2 ?? "");
    const [postcode, setPostcode] = useState(quotation.billingAddress?.zipcode ?? "");
    const [city, setCity] = useState(quotation.billingAddress?.city ?? "");
    const [country, setCountry] = useState(quotation.billingAddress?.country ?? "");//quotation.billingAddress.country);
    const [vat, setVat] = useState(quotation.billingAddress?.vat ?? "");
    const [eori, setEori] = useState(quotation.billingAddress?.eori ?? "");

    const loc = useLocation()
    const hist = useNavigate()

    const [allowChoosingMethod, setAllowChoosingMethod] = useState(false);
    const filteredMethods = filterBySupportedMethods(
        props.supportedMethods,
        methods,
    );


    const save = () => {

        checkoutClient.UpdateAddress(props.quotationId, {
            name,
            companyName,
            street: streetOne,
            addressLine2: streetTwo,
            zipCode: postcode,
            city,
            country,
            vat,
            eori,
        })
            .then((v) => {
                if (v.status >= 200 && v.status < 400) {
                    props.onRefresh();
                    if (filteredMethods.length > 1)
                        setAllowChoosingMethod(true)
                    else
                        hist(`${loc.pathname}/${quotation.paymentMethods[0]}`)
                }
            });
    };

    return (
        <Container className="ease-transition mt-4">
            <Row>
                <Col>
                    <Row>
                        <Col className="px-0">
                            <Form
                                className="bg-light"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div>

                                    { allowChoosingMethod && <div className="bg-traxisblue rounded-bottom align-bottom  py-2 w-100">
                                    <Col className={'px-2'} style={{display: "flex", justifyContent: "space-between",alignItems:"center",width: "100%",}}>
                                        <h5 className="font-weight-bold text-white mt-1">{t("addressDetails")}</h5>
                                        <h5>
                                            <i onClick={() => setAllowChoosingMethod(false)}
                                               style={{paddingRight: '1.4rem', color: 'white'}}
                                               className={`fas fa-chevron-down collapse-arrow`}/>
                                        </h5>

                                    </Col>
                                    </div>}
                                    <Collapse in={!allowChoosingMethod}>
                                    <div>
                                        <h5 className={'pt-1 px-4 fw-bold'}>{t("addressDetails")}</h5>
                                    <Row>
                                        <Col>
                                            <TextInput
                                                key="name"
                                                label={t("nameLabel")}
                                                val={name}
                                                readonly={updateAddressInputConfigurations.isDisabled}
                                                setFunc={setName}
                                            />
                                        </Col>
                                        {updateAddressInputConfigurations.companyName && (
                                            <Col md={6}>
                                                <TextInput
                                                    label={t("companyNameLabel")}
                                                    val={companyName}
                                                    readonly={updateAddressInputConfigurations.isDisabled}
                                                    setFunc={setCompanyName}
                                                />
                                            </Col>
                                        )}
                                    </Row>

                                    {updateAddressInputConfigurations.address && (
                                        <>
                                            <Row>
                                                <Col>
                                                    <TextInput
                                                        label={t("addressLabel")}
                                                        val={streetOne}
                                                        readonly={updateAddressInputConfigurations.isDisabled}
                                                        setFunc={setStreetOne}
                                                    />
                                                </Col>
                                            </Row>
                                            {updateAddressInputConfigurations.additionalAddress && (
                                                <Row>
                                                    <Col>
                                                        <TextInput
                                                            label={t("additionalAddressLabel")}
                                                            val={streetTwo}
                                                            readonly={updateAddressInputConfigurations.isDisabled}
                                                            setFunc={setStreetTwo}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}

                                            <Row>
                                                <Col md={4}>
                                                    <TextInput
                                                        label={t("zipcodeLabel")}
                                                        val={postcode}
                                                        readonly={updateAddressInputConfigurations.isDisabled}
                                                        setFunc={setPostcode}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <TextInput
                                                        label={t("cityLabel")}
                                                        val={city}
                                                        readonly={updateAddressInputConfigurations.isDisabled}
                                                        setFunc={setCity}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <FormLabel>{t("countryLabel")}</FormLabel>
                                                    <FormControl
                                                        onChange={(e) => setCountry(e.target.value)}
                                                        value={country === '' ? "select" : country}
                                                        disabled={updateAddressInputConfigurations.isDisabled}
                                                        as="select"
                                                    >
                                                        <option key={'select'} value={''}>
                                                            {t("countryLabel")}
                                                        </option>
                                                        {countries.map((c) => (
                                                            <option key={c.code} value={c.code}>
                                                                {c.name}
                                                            </option>
                                                        ))}
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                    <Row>
                                        {updateAddressInputConfigurations.vat && (
                                            <Col md={4}>
                                                <TextInput
                                                    label={t("vatLabel")}
                                                    val={vat}
                                                    readonly={updateAddressInputConfigurations.isDisabled}
                                                    setFunc={setVat}
                                                />
                                            </Col>
                                        )}

                                        {updateAddressInputConfigurations.eori && (
                                            <Col md={4}>
                                                <TextInput
                                                    label={t("eoriLabel")}
                                                    val={eori}
                                                    readonly={updateAddressInputConfigurations.isDisabled}
                                                    setFunc={setEori}
                                                />
                                            </Col>
                                        )}
                                        <Col style={{ display: 'flex' }} className="flex-row justify-content-end align-items-end">
                                            <Button
                                                onClick={() => save()}
                                                className="bg-traxisblue w-100 mt-2"
                                            >
                                                {t("updateAddressButton")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    </div>
                                </Collapse>

                                {/*<Accordion  style={{margin:'0px'}} activeKey={!allowChoosingMethod ? "0" : "1"} onSelect={() => setAllowChoosingMethod(!allowChoosingMethod)}>*/}
                                {/*    <Accordion.Item eventKey="0">*/}
                                {/*        <Accordion.Header >*/}
                                {/*            <Row*/}
                                {/*                style={{*/}
                                {/*                    display: 'flex',*/}
                                {/*                    justifyContent: 'space-between',*/}
                                {/*                    alignItems:'flex-end',*/}
                                {/*                    flexDirection: 'row',*/}
                                {/*                    width: '100%',*/}

                                {/*                }}*/}
                                {/*                className={` ${allowChoosingMethod ? "text-white align-bottom bg-blue-500 " : "bg-blue-500"}`}*/}
                                {/*                onClick={() => setAllowChoosingMethod(prev => !prev)}*/}
                                {/*            >*/}
                                {/*                {quotation.scenario.configurations.paymentPageConfigurations.billingAddress ? (*/}
                                {/*                    <Col className="ml-3">*/}
                                {/*                        <h5 className="font-weight-bold mt-1">{t("addressDetails")}</h5>*/}
                                {/*                    </Col>*/}
                                {/*                ):<Col></Col>}*/}

                                {/*                        <i*/}
                                {/*                            style={{ width:'24px'}}*/}
                                {/*                            className={`fas ease-transition fa-chevron-down ${*/}
                                {/*                                !allowChoosingMethod ? "collapse-arrow-collapsed" : "collapse-arrow"*/}
                                {/*                            }`}*/}
                                {/*                        ></i>*/}


                                {/*            </Row>*/}
                                {/*        </Accordion.Header>*/}

                                {/*        <Accordion.Body>*/}
                                {/*            /!* Your Accordion body content here *!/*/}
                                {/*            <Row>*/}
                                {/*                <Col>*/}
                                {/*                    <TextInput*/}
                                {/*                        key="name"*/}
                                {/*                        label={t("nameLabel")}*/}
                                {/*                        val={name}*/}
                                {/*                        readonly={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                        setFunc={setName}*/}
                                {/*                    />*/}
                                {/*                </Col>*/}
                                {/*                {updateAddressInputConfigurations.companyName && (*/}
                                {/*                    <Col md={6}>*/}
                                {/*                        <TextInput*/}
                                {/*                            label={t("companyNameLabel")}*/}
                                {/*                            val={companyName}*/}
                                {/*                            readonly={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                            setFunc={setCompanyName}*/}
                                {/*                        />*/}
                                {/*                    </Col>*/}
                                {/*                )}*/}
                                {/*            </Row>*/}

                                {/*            {updateAddressInputConfigurations.address && (*/}
                                {/*                <>*/}
                                {/*                    <Row>*/}
                                {/*                        <Col>*/}
                                {/*                            <TextInput*/}
                                {/*                                label={t("addressLabel")}*/}
                                {/*                                val={streetOne}*/}
                                {/*                                readonly={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                                setFunc={setStreetOne}*/}
                                {/*                            />*/}
                                {/*                        </Col>*/}
                                {/*                    </Row>*/}
                                {/*                    {updateAddressInputConfigurations.additionalAddress && (*/}
                                {/*                        <Row>*/}
                                {/*                            <Col>*/}
                                {/*                                <TextInput*/}
                                {/*                                    label={t("additionalAddressLabel")}*/}
                                {/*                                    val={streetTwo}*/}
                                {/*                                    readonly={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                                    setFunc={setStreetTwo}*/}
                                {/*                                />*/}
                                {/*                            </Col>*/}
                                {/*                        </Row>*/}
                                {/*                    )}*/}

                                {/*                    <Row>*/}
                                {/*                        <Col md={4}>*/}
                                {/*                            <TextInput*/}
                                {/*                                label={t("zipcodeLabel")}*/}
                                {/*                                val={postcode}*/}
                                {/*                                readonly={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                                setFunc={setPostcode}*/}
                                {/*                            />*/}
                                {/*                        </Col>*/}
                                {/*                        <Col md={4}>*/}
                                {/*                            <TextInput*/}
                                {/*                                label={t("cityLabel")}*/}
                                {/*                                val={city}*/}
                                {/*                                readonly={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                                setFunc={setCity}*/}
                                {/*                            />*/}
                                {/*                        </Col>*/}
                                {/*                        <Col md={4}>*/}
                                {/*                            <FormLabel>{t("countryLabel")}</FormLabel>*/}
                                {/*                            <FormControl*/}
                                {/*                                onChange={(e) => setCountry(e.target.value)}*/}
                                {/*                                value={country === '' ? "select" : country}*/}
                                {/*                                disabled={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                                as="select"*/}
                                {/*                            >*/}
                                {/*                                <option key={'select'} value={''}>*/}
                                {/*                                    {t("countryLabel")}*/}
                                {/*                                </option>*/}
                                {/*                                {countries.map((c) => (*/}
                                {/*                                    <option key={c.code} value={c.code}>*/}
                                {/*                                        {c.name}*/}
                                {/*                                    </option>*/}
                                {/*                                ))}*/}
                                {/*                            </FormControl>*/}
                                {/*                        </Col>*/}
                                {/*                    </Row>*/}
                                {/*                </>*/}
                                {/*            )}*/}

                                {/*            <Row>*/}
                                {/*                {updateAddressInputConfigurations.vat && (*/}
                                {/*                    <Col md={4}>*/}
                                {/*                        <TextInput*/}
                                {/*                            label={t("vatLabel")}*/}
                                {/*                            val={vat}*/}
                                {/*                            readonly={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                            setFunc={setVat}*/}
                                {/*                        />*/}
                                {/*                    </Col>*/}
                                {/*                )}*/}

                                {/*                {updateAddressInputConfigurations.eori && (*/}
                                {/*                    <Col md={4}>*/}
                                {/*                        <TextInput*/}
                                {/*                            label={t("eoriLabel")}*/}
                                {/*                            val={eori}*/}
                                {/*                            readonly={updateAddressInputConfigurations.isDisabled}*/}
                                {/*                            setFunc={setEori}*/}
                                {/*                        />*/}
                                {/*                    </Col>*/}
                                {/*                )}*/}
                                {/*                <Col style={{ display: 'flex' }} className="flex-row justify-content-end align-items-end">*/}
                                {/*                    <Button*/}
                                {/*                        onClick={() => save()}*/}
                                {/*                        className="bg-traxisblue w-100 mt-2"*/}
                                {/*                    >*/}
                                {/*                        {t("updateAddressButton")}*/}
                                {/*                    </Button>*/}
                                {/*                </Col>*/}
                                {/*            </Row>*/}
                                {/*        </Accordion.Body>*/}
                                {/*    </Accordion.Item>*/}
                                {/*</Accordion>*/}

                                </div>

                            </Form>
                        </Col>
                    </Row>
                    {allowChoosingMethod && <div>

                        <Row style={{justifyContent: 'space-evenly'}}>
                            {filteredMethods.map((v, i) => (
                                <Col
                                    key={i}
                                    sm={filteredMethods.length === 1 ? 4 : 4}
                                    className={`px-0 `}
                                >
                                    {v}
                                </Col>
                            ))}
                        </Row>
                    </div>}
                </Col>
            </Row>
        </Container>
    );
};
export default PaymentMethod;
